<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <div>
    <ejs-grid
      id="overviewgrid"
      ref="overviewgrid"
      :data-source="getTradeData"
      :selection-settings="selectionSettings"
      :edit-settings="editSettings"
      :filter-settings="filterOptions"
      :show-column-chooser="true"
      :allow-selection="true"
      :allow-sorting="true"
      :allow-filtering="true"
      :allow-resizing="true"
      :enable-virtualization="true"
      height="300"
      row-height="38"
      :enable-hover="false"
      :enable-header-focus="true"
      :load="load"
      :toolbar="toolbar"
      :toolbar-click="toolbarClick"
      :action-begin="actionTableBegin"
      :action-complete="actionTableComplete"
      :allow-grouping="true"
      :context-menu-items="contextMenuItems"
      :context-menu-click="contextMenuClick"
      :data-bound="dataBound"
      :command-click="commandColumm"
    >
      <e-columns>
        <e-column
          type="checkbox"
          :allow-filtering="false"
          :allow-sorting="false"
          width="60"
        />
        <e-column
          field="id"
          :visible="false"
          header-text="ID"
          :is-primary-key="true"
          width="130"
        />
        <e-column
          field="name"
          header-text="Nome"
          width="200"
          :filter="filter"
          clip-mode="EllipsisWithTooltip"
        />
        <e-column
          field="disponibile"
          header-text="Disponibile"
          width="170"
          clip-mode="EllipsisWithTooltip"
        />
        <e-column
          field="quantity"
          header-text="Quantità"
          width="230"
          :filter="filter"
        />
        <e-column
          field="price"
          header-text="Prezzo"
          width="140"
          :filter="filter"
        />
        <e-column
          field="Azioni"
          :lock-column="true"
          header-text="Azioni"
          width="240"
          :template="cTemplate"
          :allow-editing="false"
          :commands="commands"
          :allow-sorting="false"
          :allow-filtering="false"
        />
      </e-columns>
    </ejs-grid>
    <SidebarProductEventHandler
      ref="sidebar-product-event-handler"
      @add-selected-product="addSelectProduct"
    />
  </div>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, Toolbar, Edit, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, VirtualScroll,
} from '@syncfusion/ej2-vue-grids'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import SidebarProductEventHandler from '@/components/sidebar/SidebarProductEventHandler.vue'

setCulture('it-IT')
L10n.load(italian)
Vue.use(GridPlugin)

export default Vue.extend({
  components: {
    SidebarProductEventHandler,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({

          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    warehouse: {},
    commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
      ],
    contextMenuItems: ['Add', 'AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Delete', 'Save', 'Cancel', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup', { text: 'Aggiungi Prodotto', target: '.e-content', id: 'addProduct', iconCss: 'e-icons e-plus' }],
    toolbar: ['Add', 'Edit','Delete', 'Cancel', 'ColumnChooser', { text: 'Aggiungi Prodotto', tooltipText: 'Aggiungi Prodotto', prefixIcon: 'e-plus', id: 'addProduct' }],
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 0,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    country1: [
      { email: 'United States', id_user: '1' },
      { email: 'Australia', id_user: '2' },
    ],
    fields: { text: 'text', value: 'value' },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  computed: {
    country() {
      return this.country1
    },
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          ID: code + i,
          Nome:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          Descrizione: designation[Math.floor(Math.random() * designation.length)],
          TipoMagazzino: location[Math.floor(Math.random() * location.length)],
          DimensioniMagazzino: trustworthiness[Math.floor(Math.random() * trustworthiness.length)],
        })
      }
      this.stTime = performance.now()
      return tradeData
    },
  },
  methods: {
    dataBound: function() {
      // console.log(this.$refs.overviewgrid)
      // this.$refs.overviewgrid.autoFitColumns(['Employees', 'Designation', 'Mail', 'Location', 'Status', 'Trustworthiness', 'Rating', 'Software', 'CurrentSalary', 'Azioni' ]);
      // this.$refs.overviewgrid.autoFitColumns(['Employees', 'Designation', 'Mail', 'Location', 'Status', 'Trustworthiness', 'Rating', 'Software', 'CurrentSalary', 'Azioni' ]);
    },
    commandColumm(args) {
      if(args.commandColumn.type === 'Dettagli') {
        }
    },
    contextMenuClick(args) {
      // console.log('contextMenuClick', args)
      if(args.item.id === 'addProduct') {
        this.$refs['sidebar-product-event-handler'].openSidebar()
      }
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
    },
    pdfHeaderQueryCellInfo() {
      args.cell.row.pdfGrid.repeatHeader = true;
    },
    toolbarClick(args) {
      if (args.item.id === 'addProduct') {
        this.$refs['sidebar-product-event-handler'].openSidebar()
      }
    },
    addSelectProduct(products) {
      // console.log('eventemitter', products)
      const productsActual = [...this.$refs.overviewgrid.ej2Instances.dataSource]
      // console.log('attuali', productsActual)
      productsActual.unshift(...products)
      // console.log('attuali unshift', productsActual)
      this.$refs.overviewgrid.ej2Instances.dataSource = productsActual
    },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
    prepareSidebarToEdit(user, title) {
      // console.log(this.collaborators)
      if (title === 'Modifica Magazzino') {
        this.titleCollaboratorEventHandler = title
        const roles = user.ruolo.split('/')
        const userEdited = { ...user }
        userEdited.ruolo = [...roles]
        // console.log(userEdited)
        this.collaborator = { ...userEdited }
        // console.log('collaborator', this.collaborator)
      } else {
        this.titleCollaboratorEventHandler = title
        this.warehouse = {
          avatar: '',
          nome: '',
          cognome: '',
          ruolo: '',
          email: '',
          codice_fiscale: '',
          partita_iva: '',
          stato: '',
          cellulare: '',
          ACL: '',
          data_nascita: '',
          stato_nascita: '',
          prov_nascita: '',
          citta_nascita: '',
          sesso: '',
          codice_sdi: '',
          ragione_sociale: '',
        }
      }
      // console.log('prepareSidebarToEdit', user)
      // console.log('prepareSidebarToEdit', title)
      // console.log('editCustomer', user)
      // console.log('collaborator', this.collaborator)
      this.$refs['sidebar-collaborators'].setFormToEdit(this.collaborator)
    },
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, Edit, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Filter, VirtualScroll],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
