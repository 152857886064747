<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <!-- <h3 class="text-primary invoice-logo">
                      Vuexy
                    </h3> -->
                  </div>
                  <b-card-text class="mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </b-card-text>
                  <b-card-text class="mb-25">
                    San Diego County, CA 91905, USA
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </b-card-text>
                </div>
                <div
                  class="d-flex flex-column"
                >
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <span class="title mr-2">
                      Listino:
                    </span>
                    <v-select
                      v-model="invoiceData.client"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clients"
                      label="company"
                      input-id="invoice-data-client1"
                      :clearable="false"
                      style="width:150px"
                    />
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <span class="title mr-2">
                      Referente:
                    </span>
                    <v-select
                      v-model="invoiceData.client"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clients"
                      label="company"
                      input-id="invoice-data-client2"
                      :clearable="false"
                      style="width:150px"
                    />
                  </div>
                </div>
                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id3"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.issuedDate"
                      static
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Sezionale:
                    </span>
                    <v-select
                      v-model="invoiceData.client"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clients"
                      label="company"
                      input-id="invoice-data-client4"
                      :clearable="false"
                      style="width:90%"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0 py-0 my-0"
            >
              <b-row class="invoice-spacing">
                <b-col cols="12">
                  <div class="mb-1">
                    <b-form-radio
                      v-model="tipoCliente"
                      name="some-radios"
                      value="P"
                    >
                      Privato e/o Azienda
                    </b-form-radio>
                    <b-form-radio
                      v-model="tipoCliente"
                      name="some-radios"
                      value="PA"
                    >
                      Pubblica Amministrazione
                    </b-form-radio>
                  </div>
                </b-col>
                <!-- Col: Invoice To -->
                <b-col
                  v-if="tipoCliente === 'P'"
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Intestatario Fattura:
                  </h6>
                  <!-- Select Client -->
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="company"
                    input-id="invoice-data-client0"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer2</span>
                      </li>
                    </template>
                  </v-select>
                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col
                  v-if="tipoCliente === 'PA'"
                  cols="12"
                  xl="6"
                  class=" d-flex flex-column"
                >
                  <b-row class="p-0 m-0">
                    <b-col
                      cols="5"
                      class="ml-0 pl-0"
                    >
                      <div>
                        <h6 class="mb-1">
                          Codice PA:
                        </h6>
                        <!-- Select Client -->
                        <v-select
                          v-model="invoiceData.tipoCliente"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="clients"
                          label="company"
                          input-id="invoice-data-client00"
                          :clearable="false"
                        >
                          <template />
                        </v-select>
                      </div>
                    </b-col>
                    <b-col
                      cols="7"
                      class="mr-0 pr-0"
                    >
                      <div>
                        <h6 class="mb-1">
                          Denominazione:
                        </h6>
                        <!-- Select Client -->
                        <b-form-input disabled />
                        <!-- Selected Client -->
                        <div
                          v-if="invoiceData.client"
                          class="mt-1"
                        >
                          <h6 class="mb-25">
                            {{ invoiceData.client.name }}
                          </h6>
                          <b-card-text class="mb-25">
                            {{ invoiceData.client.company }}
                          </b-card-text>
                          <b-card-text class="mb-25">
                            {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                          </b-card-text>
                          <b-card-text class="mb-25">
                            {{ invoiceData.client.contact }}
                          </b-card-text>
                          <b-card-text class="mb-0">
                            {{ invoiceData.client.companyEmail }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      cols="12"
                      class="p-0 m-0 mt-1"
                    >
                      <h6 class="mb-1">
                        Riferimento Amministrazione:
                      </h6>
                      <!-- Select Client -->
                      <b-form-input placeholder="Codice Utenza/POD" />
                    </b-col>
                  </b-row>
                </b-col>
                <!-- Col: Payment Details -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-1">
                    Indirizzo Spedizione:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    v-model="invoiceData.client"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clients"
                    label="company"
                    input-id="invoice-data-client0000"
                    :clearable="false"
                  >
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer</span>
                      </li>
                    </template>
                  </v-select>

                  <!-- Selected Client -->
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Riferimenti Repeater  -->
            <b-card-body
              v-if="tipoCliente === 'PA'"
              class="py-0 my-0"
            >
              <b-row
                class="mx-1 p-0"
              >
                <b-col cols="12">
                  <h6>Riferimenti</h6>
                </b-col>
                <!-- Item Name -->
                <b-col>
                  <b-form-group
                    label="Tipo"
                    label-for="item-name"
                  >
                    <b-form-input
                      id="item-name"
                      type="text"
                      placeholder="Vuexy Admin Template"
                    />
                  </b-form-group>
                </b-col>

                <!-- Cost -->
                <b-col>
                  <b-form-group
                    label="N.Doc."
                    label-for="cost"
                  >
                    <b-form-input
                      id="cost"
                      type="number"
                      placeholder="32"
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col>
                  <b-form-group
                    label="C.I.G."
                    label-for="quantity"
                  >
                    <b-form-input
                      id="quantity"
                      type="number"
                      placeholder="1"
                    />
                  </b-form-group>
                </b-col>

                <!-- Profession -->
                <b-col>
                  <b-form-group
                    label="C.U.P."
                    label-for="cost"
                  >
                    <b-form-input
                      id="cost"
                      type="number"
                      placeholder="32"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    label="Data"
                    label-for="cost"
                  >
                    <b-form-input
                      id="cost"
                      type="number"
                      placeholder="32"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    label="Cod.Comm./Conv."
                    label-for="cost"
                  >
                    <b-form-input
                      id="cost"
                      type="number"
                      placeholder="32"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <products-table-invoice />
            </b-card-body>
            <!-- Ritenuta rivalsa cassa -->
            <b-card-body>
              <b-row
                class="m-1"
              >
                <app-collapse
                  type="border"
                  class="w-100"
                >
                  <app-collapse-item
                    title="Metodi di Pagamento e Scadenze"
                  >
                    <b-row class="m-1 mt-0">
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Modalità'">
                          <v-select
                            v-model="invoiceData.client"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="clients"
                            label="company"
                            input-id="invoice-data-client0000"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Scadenze'">
                          <v-select
                            v-model="invoiceData.client"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="clients"
                            label="company"
                            input-id="invoice-data-client0000"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                  <app-collapse-item
                    title="Ritenuta, rivalsa, cassa"
                  >
                    <b-row class="m-1 mt-0">
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Cassa'">
                          <b-input-group
                            append="%"
                            class="input-group-merge"
                          >
                            <b-form-input disabled />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Rivalsa'">
                          <b-input-group
                            append="%"
                            class="input-group-merge"
                          >
                            <b-form-input disabled />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Ritenuta d\'acconto'">
                          <b-input-group
                            append="%"
                            class="input-group-merge"
                          >
                            <b-form-input disabled />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-group :label="'Sul'">
                          <b-input-group
                            append="%"
                            class="input-group-merge"
                          >
                            <b-form-input disabled />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                  <app-collapse-item
                    title="Marca da bollo, Split Payment"
                  >
                    <b-row class="m-1 mt-0">
                      <b-col
                        cols="6"
                      >
                        <b-form-group :label="'Marca da bollo'">
                          <b-input-group>
                            <b-form-input />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="6"
                      >
                        <b-form-group :label="'Valore'">
                          <b-input-group>
                            <b-form-input />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Split Payment"
                          label-for="cost"
                        >
                          <b-form-checkbox
                            value="B"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
              </b-row>
            </b-card-body>
            <!-- Details Payment  -->
            <b-card-body class="invoice-padding pb-0">
              <h6 class="mb-2">
                Payment Details:
              </h6>
              <table>
                <tbody>
                  <tr>
                    <td class="pr-1">
                      Total Due:
                    </td>
                    <td><span class="font-weight-bold">$12,110.55</span></td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Bank name:
                    </td>
                    <td>American Bank</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      Country:
                    </td>
                    <td>United States</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      IBAN:
                    </td>
                    <td>ETD95476213874685</td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      SWIFT code:
                    </td>
                    <td>BR91905</td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
            <hr class="invoice-spacing">
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                  <label
                    for="invoice-data-sales-person"
                    class="text-nowrap mr-50"
                  >Sales Person:</label>
                  <b-form-input
                    id="invoice-data-sales-person"
                    v-model="invoiceData.salesPerson"
                    placeholder="Edward Crowley"
                  />
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $1800
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">
                        21%
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $1690
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Send Invoice
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Preview
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            Save
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-form-group
            label="Accept Payment Via"
            label-for="payment-method"
          >
            <v-select
              v-model="invoiceData.paymentMethod"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>

          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

          <!-- Payment Terms -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="patymentTerms">Payment Terms</label>
            <b-form-checkbox
              id="patymentTerms"
              :checked="true"
              switch
            />
          </div>

          <!-- Client Notes -->
          <div class="d-flex justify-content-between align-items-center my-1">
            <label for="clientNotes">Client Notes</label>
            <b-form-checkbox
              id="clientNotes"
              :checked="true"
              switch
            />
          </div>

          <!-- Payment Stub -->
          <div class="d-flex justify-content-between align-items-center">
            <label for="paymentStub">Payment Stub</label>
            <b-form-checkbox
              id="paymentStub"
              switch
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <invoice-sidebar-add-new-customer />
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, VBToggle, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse_original/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse_original/AppCollapseItem.vue'
import store from '@/store'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import ProductsTableInvoice from '../product-table/ProductsTableInvoice.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    vSelect,
    Logo,
    InvoiceSidebarAddNewCustomer,
    ProductsTableInvoice,
    BFormRadio,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      /* this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      }) */
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const clients = ref([])
    store.dispatch('app-invoice/fetchClients')
      .then(response => { clients.value = response.data })

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }
    const tipoCliente = ref('P')
    const invoiceData = ref({
      id: 5037,
      client: null,
      tipoCliente: 'P',
      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      tipoCliente,
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
